.menuHeader{
    margin-top: 80px;
    width: 100%;
    height: 45px;
    background: rgb(82, 80, 80);
    border-bottom: 1px solid #cecece;
    
    cursor: "pointer";
    transition: 'all 0.5s ease-out';
    font-family: "'Fira Sans', sans-serif";
}
.menuHeader ul{
    list-style: none;
    margin: 0;
    padding: 0;
}
.menuHeader > ul{
    display: flex;
    margin: 0 50px;
    
    justify-content: space-evenly;
    position: relative;
}
.menuHeader > ul > li > span{
    display: block;
    line-height: 45px;
    cursor: pointer;
    padding: 0 20px;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    

}
.menuHeader > ul > li > span:hover{
    
    color: #cecece;
    /* background-color:  rgb(219, 226, 215); */
}
.menuHeader > ul > li > ul{
    position: absolute;
    background: #fff;
    left: 100px;
    right: 100px;
    display: none;
    border: 1px solid #cecece;
    z-index: 1041;
}
.menuHeader > ul > li:hover ul{
    display: block;
    padding: 5px;
}
.menuHeader > ul > li > ul > li{
    margin: 10px 10px;
    min-width: 200px;
    background: #f3f1f1;
}
.menuHeader > ul > li > ul > li{
    float: left;
    padding: 5px;
}
.menuHeader > ul > li > ul > li > div{
    font-weight: bold;
    display: block;
    cursor: "pointer";
    /* color: Gray; */
      

}
.menuHeader > ul > li > ul > li div{
    padding: 3px 0;
    display: block;
    font-size: 14px;
    text-decoration: none;
    cursor: "pointer";
    color: #111;
    
}
.menuHeader > ul > li > ul > li div:hover{
    color: #53E7BC;
}

@media only screen and (max-width: 750px) {
    .menuHeader {
      width: 100%;
    }
    .menuHeader > ul > li > span{
        padding: 0 15px;
    }
  }

@media only screen and (max-width: 500px) {
    .menuHeader {
      width: 100%;
    }
    .menuHeader > ul > li > span{
        padding: 0 12px;
    }
  }



@media only screen and (max-width: 200px) {
    .menuHeader {
      width: 100%;
    }
    .menuHeader > ul > li > span{
        padding: 0 10px;
    }
  }

