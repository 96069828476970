/* Loading.css */
.loading {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(211, 197, 197, 0.5); /* Transparent background */
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    z-index: 9999; 
  }
  
  .loader {
   border: 4px solid rgba(255, 255, 255, 0.3); /* Loading spinner style */
  border-top: 4px solid #3498db; /* Loading spinner color */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  